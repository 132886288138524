import React from 'react'

const Burger = ({burgerClickHandler, activeBurger}) => {
    return(
        <button onClick={(burgerClickHandler)} className={!activeBurger ? 'burger' : 'burger open'}>
            <span className="lines">
                <span className="line line-top"></span>
                <span className="line line-center"></span>
                <span className="line line-bottom"></span>
            </span>
        </button>
    )
}

export default Burger