import React from "react";
import Header from "./Header/Header";
import Menu from "./Header/Menu/Menu";
import MessageButtonFixed from "./MessageButtonFixed/MessageButtonFixed";
import PhonesWidget from "./PhonesWidget/PhonesWidget";

const HeaderSaidBar = () => {
  const [activeBurger, setActiveBurger] = React.useState(false);
  const [activeWidget, setActiveWidget] = React.useState(false);

  function burgerClickHandler(e) {
    e.preventDefault();
    setActiveBurger(!activeBurger);
  }

  function closeNavHandler() {
    setActiveBurger(false);
    setActiveWidget(false);
  }

  function widgetClickHandler(e) {
    e.preventDefault();
    setActiveWidget(!activeWidget);
  }

  return (
    <div className="header-fixed">
      <div className="header-fixed_wrapper">
        <Header
          burgerClickHandler={burgerClickHandler}
          activeBurger={activeBurger}
        />
        <Menu activeBurger={activeBurger} closeNavHandler={closeNavHandler} />
        <PhonesWidget
          activeWidget={activeWidget}
          widgetClickHandler={widgetClickHandler}
        />
        <MessageButtonFixed />
      </div>
      <div
        onClick={closeNavHandler}
        role="presentation"
        className={!activeBurger && !activeWidget ? "overlay" : "overlay open"}
      ></div>
    </div>
  );
};

export default HeaderSaidBar;
