import React from "react";
import { Trans } from "gatsby-plugin-react-i18next";
import Map from "../../svg/Map.svg";
import Location from "../../svg/location.svg";
import Nunox from "../../svg/nunox.svg";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="header-section">
          <h3 className="h3">
            <Trans>Contacts</Trans>
          </h3>
        </div>
        <div className="footer-wrapper">
          <a
            href="https://goo.gl/maps/jML4jgGSaVghJjjx9"
            className="footer-map"
            target="blank"
          >
            <Map />
            <span className="location">
              <Location />
              <span className="location-title">
                <Trans>Open</Trans> <br />
                <Trans>Map</Trans>
              </span>
            </span>
          </a>
          <div className="footer-contacts">
            <ul className="contacts">
              <li className="contacts-item">
                <span className="contacts-item_title">
                  <Trans>Head office</Trans>:
                </span>
              </li>
              <li className="contacts-item">
                <a href="tel:+380676576647" className="contacts-item_link">
                  +380676576647
                </a>
              </li>
              <li className="contacts-item">
                <p className="contacts-item_address">
                  м.Київ, вул. Васильківська 100а
                </p>
              </li>
              <li className="contacts-item">
                <a
                  href="mailto:office@tpkoil.com"
                  className="contacts-item_link"
                >
                  office@tpkoil.com
                </a>
              </li>
              <li className="contacts-item">
                <span className="contacts-item_title">
                  <Trans>Petroleum Sales</Trans>:
                </span>
              </li>
              <li className="contacts-item">
                <a href="tel:+380675515170" className="contacts-item_link">
                  +380675515170
                </a>
              </li>

              <li className="contacts-item">
                <span className="contacts-item_title">
                  <Trans>Fuel coupons</Trans>:
                </span>
              </li>
              <li className="contacts-item">
                <a href="tel:+380991988998" className="contacts-item_link">
                  +380991988998
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom_line">
          <span className="copyright">
            2021 © <Trans>Technological Fuel Company</Trans>
          </span>
          <a href="https://nunox.co/" className="credits" target="blank">
            <Nunox />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
