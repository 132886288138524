import React from "react";
import { Trans } from "gatsby-plugin-react-i18next";
import PhoneIcon from "../../../svg/phone.svg";

const PhonesWidget = ({ activeWidget, widgetClickHandler }) => {
  const phonesData = [
    {
      title: "Head office",
    },
    {
      phone: "067 657 66 47",
      to: "tel:+380676576647",
    },
    {
      title: "Sales department",
    },
    {
      phone: "067 551 51 70",
      to: "tel:+380675515170 ",
    },
  ];
  return (
    <div className={!activeWidget ? "phone-widget" : "phone-widget open"}>
      <div className="widget-button_wrapper">
        <span className="widget-title">
          <Trans>Call</Trans>
        </span>
        <button
          onClick={widgetClickHandler}
          className={!activeWidget ? "widget-button" : "widget-button open"}
        >
          <span className="button-icons">
            <i className="button-icon button-icon_phone">
              <PhoneIcon />
            </i>
            <i className="button-icon button-icon_close"></i>
          </span>
        </button>
      </div>
      <ul className={!activeWidget ? "widget-list" : "widget-list open"}>
        {phonesData.map((item, index) => {
          if (item.title) {
            return (
              <li key={index} className="widget-list_item">
                <p className="widget-list_title">
                  <Trans>{item.title}</Trans>:
                </p>
              </li>
            );
          } else {
            return (
              <li key={index} className="widget-list_item">
                <a href={item.to} className="widget-list_link">
                  {item.phone}
                </a>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default PhonesWidget;
