import React from "react";
import Message from "../../../../svg/chat.svg";
import { Trans } from "gatsby-plugin-react-i18next";
const MessageButton = () => {
  return (
    <a href="https://wa.me/+380675515170" className="message-button">
      <i className="message-button_icon">
        <Message />
      </i>
      <span className="message-button-title">
        <Trans>Write</Trans>
      </span>
    </a>
  );
};

export default MessageButton;
