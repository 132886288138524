import React from "react";
import { Link, Trans } from "gatsby-plugin-react-i18next";
import Languages from "../Languages/Languages";
import MessageButton from "./MessageButton";

const Menu = ({ activeBurger, closeNavHandler }) => {
  const dataMenu = [
    {
      title: "Home",
      to: "/",
      // count: ".01",
    },
    {
      title: "Petrol",
      to: "/petroleum-products/",
      // count: ".02",
    },
    {
      title: "Logistics",
      to: "/logistics/",
      // count: ".03",
    },
    {
      title: "Storage",
      to: "/storage/",
      // count: ".04",
    },
    {
      title: "About",
      to: "/about-company/",
      // count: ".05",
    },
    {
      title: "Contact",
      to: "/contacts/",
      // count: ".06",
    },
  ];

  return (
    <nav className={!activeBurger ? "navigation" : "navigation open"}>
      <div className="navigation-lang">
        <Languages />
      </div>

      <ul className="menu">
        {dataMenu.map((item, index) => {
          return (
            <li key={`${item.title}_${index}`} className="menu-item">
              <Link
                onClick={closeNavHandler}
                to={item.to}
                className="menu-item_link"
              >
                {/* <span className="menu-item_count">{item.count}</span> */}
                <span className="menu-item_title">
                  <Trans>{item.title}</Trans>
                </span>
              </Link>
            </li>
          );
        })}
      </ul>
      <MessageButton />
    </nav>
  );
};

export default Menu;
