import React from "react";
import Footer from "./Footer/Footer";
import HeaderSaidBar from "./HeaderSaidBar/HeaderSaidBar";

const Layout = ({ children }) => {
  return (
    <>
      <HeaderSaidBar />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
