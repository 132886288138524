import React from "react";
import { Link } from "gatsby-plugin-react-i18next";
import Logotype from "../../../svg/logo.svg";
import Burger from "./Burger/Burger";
import Languages from "./Languages/Languages";

const Header = ({ activeBurger, burgerClickHandler }) => {
  return (
    <header className="header">
      <Link to="/" className="logo">
        <Logotype />
      </Link>
      <Burger
        burgerClickHandler={burgerClickHandler}
        activeBurger={activeBurger}
      />
      <div className="header-lang">
        <Languages />
      </div>
    </header>
  );
};

export default Header;
