import React from "react";
import MessageButton from "../../../svg/chat.svg";

const MessageButtonFixed = () => {
  return (
    <a href="https://wa.me/+380675515170" className="message-button_fixed">
      <i className="button-icon">
        <MessageButton />
      </i>
    </a>
  );
};

export default MessageButtonFixed;
